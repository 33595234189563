import React, { useEffect, useState } from "react";
import SectionTitle from "../components/SectionTitle";
import Button from "../components/Button";
import PrimaryFont from "../components/PrimaryFont";
import Logo from "../images/logo.png";
import SplineAnimation from "../components/SplineAnimation";
import ImageOne from "../images/home/imageOne.png";
import ImageTwo from "../images/home/imageTwo.png";
import ImageThree from "../images/home/who-are-we-mobile-image-new.png";
import ImageFour from "../images/home/artwork.jpg";
import BackgroundImage from "../images/home/desktop-home-background.png";
import RainbowBackground from "../components/RainbowBackground";
import RainbowOne from "../images/desktop-rainbow-1.png";
import RainbowTwo from "../images/desktop-rainbow-2.png";
import RainbowThree from "../images/desktop-rainbow-3.png";
import { Helmet } from "react-helmet";

const Home = ({
    firstRef,
    firstRefVisible,
    secondRef,
    secondRefVisible,
    thirdRef,
    thirdRefVisible,
    fourthRef,
    fourthRefVisible,
}) => {
    // media queries
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 650px)").matches
    );
    useEffect(() => {
        window
            .matchMedia("(min-width: 650px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);

    // title
    const TITLE = "The Funtones | Home";

    // HTML
    return (
        <div className="home">
            <>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
            </>
            <header>
                <div
                    ref={firstRef}
                    className={
                        firstRefVisible
                            ? "headerContents active"
                            : "headerContents"
                    }
                >
                    <div className="logoContainer">
                        <img src={Logo} alt="" />
                    </div>
                    <div
                        className="backdrop"
                        style={{
                            backgroundImage: `url(${BackgroundImage})`,
                        }}
                    >
                        <div className="titleContainer">
                            <PrimaryFont text="A children's entertainment group." />
                        </div>
                        <div className="buttonContainer">
                            <Button
                                type="black"
                                text="upcoming shows"
                                location="/shows"
                            />
                            <Button
                                type="white"
                                text="learn about us"
                                location="/about"
                            />
                        </div>
                        {/* <SplineAnimation scene="https://prod.spline.design/noORZmbf2nli4f0W/scene.splinecode" /> */}
                    </div>
                </div>
            </header>
            <div className="sections">
                <section
                    className={secondRefVisible ? "one active" : "one"}
                    ref={secondRef}
                >
                    <div className="titleContainer">
                        <SectionTitle
                            title="Who are we?"
                            paragraph="The Funtones are a children’s entertainment group based in Melbourne. Formed in 2022, their shows feature music, singing, dancing, laughs, and they’re also a heap of fun!"
                            absoluteState="false"
                        />
                    </div>
                    {matches && (
                        <SplineAnimation scene="https://prod.spline.design/mDHY-TwHSmhk3aCs/scene.splinecode" />
                    )}
                    {!matches && (
                        <div className="mobileImage">
                            <img
                                src={ImageThree}
                                alt="illustration of Charlie, Mark and Lisa"
                            />
                        </div>
                    )}
                    <div className="buttonContainer">
                        <Button
                            type="whiteborder"
                            text="learn more about us!"
                            location="/about"
                        />
                    </div>
                </section>
                <section
                    className={thirdRefVisible ? "two active" : "one"}
                    ref={thirdRef}
                >
                    <SectionTitle
                        title="Upcoming shows!"
                        paragraph="Check out our upcoming shows with ticketing details below."
                        state="false"
                    />
                    <div className="imagesContainer">
                        <img src={ImageOne} alt="group" />
                        <img src={ImageTwo} alt="group" />
                    </div>
                    <div className="buttonContainer">
                        <Button
                            type="whiteborder"
                            text="Upcoming shows here!"
                            location="/shows"
                        />
                    </div>
                </section>
                <section
                    className={fourthRefVisible ? "three active" : "two"}
                    ref={fourthRef}
                >
                    <SectionTitle
                        title="Our music!"
                        paragraph="Get access to our music. Available on all music streaming platforms now!"
                        state="false"
                    />
                    <div className="imagesContainer">
                        <img src={ImageFour} alt="group" />
                    </div>
                    <div className="buttonContainer">
                        <Button
                            type="whiteborder"
                            text="Listen today!"
                            location="https://music.apple.com/us/artist/the-funtones/1707187191"
                            target="_blank"
                        />
                    </div>
                </section>
                <RainbowBackground type={RainbowOne} name="rainbowOne" />
                <RainbowBackground type={RainbowTwo} name="rainbowTwo" />
                <RainbowBackground type={RainbowThree} name="rainbowThree" />
            </div>
        </div>
    );
};

export default Home;
